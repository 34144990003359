$primary-color: #175fff;

$pure-white: #fff;
$light-100: #f6f7f9;

$dark-050: #a7a7a881;
$dark-100: #3a434b;
$dark-150: #353535;
$dark-180: #111111;
$dark-200: #0d0f11;
$pure-black: #000;

$gray-050: #f5f5f5;
$gray-060: #e1e1e1;
$gray-100: #d7dce0;
$gray-150: #c4c4c4;
$gray-200: #bdbdbd;
$gray-300: #aaaaaa;
$gray-400: #838383;
$gray-500: #777777;

$blue-400: #5b8ef0;
$blue-900: rgba(23, 95, 255, 0.2);
