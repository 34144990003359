// @import "../../../assets/styles/common/colors.scss";
@import "node_modules/vgg-ui-react/dist/styles/partials/colors.scss";

.notification-container {
  display: inline-flex;
  align-items: center;
  position: fixed;
  z-index: 2000;
  top: 50px;
  left: 0;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  img {
    margin-right: 18px;
  }
  .success {
    padding: 18px;
    max-width: 601px;
    display: flex;
    justify-content: space-evenly;
    background-color: $spg-100-color;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    letter-spacing: 0.4px;
    color: $spg-700-color;
  }
  .warning {
    max-width: 601px;
    padding: 18px;
    display: flex;
    justify-content: space-evenly;
    color: $spg-700-color;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    background-color: $spo-100-color;
  }
  .error {
    max-width: 601px;
    padding: 18px;
    display: flex;
    justify-content: space-evenly;
    color: $spr-600-color;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    background-color: $spr-100-color;
  }
  span {
    cursor: pointer;
    display: inline-block;
    font-size: 10px;
    padding-left: 20px;
  }
}
