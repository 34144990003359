.wallet-configuration {
  .content-container {
    display: flex;

    .content-left {
      flex: 1;
      height: 100%;

      .top-box {
        width: 100px;
        height: 100px;
        color: #777777;

        background: #f5f5f5;
        border-radius: 5px;
      }

      .left-list {
        list-style: none;
        font-size: 12px;
        padding-left: 0;
      }
    }

    .content-right {
      flex: 4;
      height: 76vh;
      overflow-y: auto;

      .summary {
        display: flex;
        border: 1px solid #d7dce0;
        padding: 16px;
        height: 120px;

        .wallet {
          flex: 1;
          padding: 0 16px;
          border-right: 1px solid #d7dce0;
          .icon {
            border-radius: 50%;
            color: #175fff;
            width: 32px;
            height: 32px;
            background: rgba(23, 95, 255, 0.1);
          }
        }

        .users {
          flex: 1;
          border-right: 1px solid #d7dce0;
          padding: 0 16px;
          .icon {
            border-radius: 50%;
            color: #175fff;
            width: 32px;
            height: 32px;
            background: rgba(23, 95, 255, 0.1);
          }
        }

        .currency {
          flex: 1;
          padding: 0 16px;
          .icon {
            border-radius: 50%;
            color: #175fff;
            width: 32px;
            height: 32px;
            background: rgba(23, 95, 255, 0.1);
          }
        }
      }

      .credited,
      .debited {
        width: 32px;
        height: 32px;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .credited {
        color: #175fff;
        background: rgba(23, 95, 255, 0.1);
      }

      .debited {
        color: #e43535;
        background: #fce9e9;
      }
    }
  }

  .back-btn {
    cursor: pointer;
  }
}

.walletInstance {
  &.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  &.modal-dialog {
    margin: 0;
    height: 100%;
    max-width: 1000px;
  }

  &.animated {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .modal-header {
    border-radius: 0;
    align-items: center;
    // padding: 1.2rem;
    flex-direction: row-reverse;

    .modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-left: 10px;
    }
  }

  .modal-content {
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.25);
    box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.25);
    background-clip: padding-box;
    height: 100%;
  }

  .modal-backdrop {
    background-color: transparent;
  }

  .modal-button-container {
    margin: 0 auto;
    width: 90%;
    max-width: 500px;
  }

  .btn {
    border-radius: 0;
  }
}
