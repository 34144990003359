.configure-container {
  .bg-gray {
    background-color: #e1e1e1;
  }

  .back-btn {
    cursor: pointer;
  }

  .actionContainer {
    width: 150px;
  }
}
