@import "~vgg-ui-react/dist/styles/partials";
@import "~vgg-ui-react/dist/styles/partials";

.service-details {
  .head-section {
    &__icon {
      width: 60px;
      height: 60px;

      background: #e8f0ff;
      border-radius: 3px;

      i {
        font-size: 25px;
        color: #175fff;
      }
    }

    &__title {
      .pg-title {
        font-weight: $sp-fontweight--600;
      }
      .pg-subtitle {
        font-weight: $sp-fontweight--600;
      }
    }
  }

  .content-section {
    .titlecontainer {
      margin-left: 64px;
    }

    .tabtitle {
      font-weight: 500 !important;
      color: #0d0f11;
      letter-spacing: 0.25px !important;
    }
    div:nth-child(2) {
      &:first-child {
        background-color: red !important;
      }
    }
  }

  .pricing {
    width: 70%;
    min-height: 300px;
    left: 280px;
    top: 628px;
    overflow: auto;

    background: #fafafa;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
    border-radius: 3px;

    .row-one {
      display: flex;
      border-bottom: 1px solid #e1e1e1;
      .right {
        border-left: 1px solid #e1e1e1;
        height: 50%;
        flex: 1;
        height: 100%;
      }

      .left {
        height: 50%;
        flex: 1;
      }
    }
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 400px;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
